import React, { Component } from 'react';
import { FileUpload } from '../FileUpload';
import { TextCard, ImageCard, DocumentCard } from '../../pages/Course/Stages/Lessons/Cards/index';
import DateTimePicker from 'src/components/DatePicker/DateTimePicker';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
import { isNil } from 'lodash';
export default class FormElement extends Component {
    handleInputChange = (e) => {
        this.props.onChange(e.target.name, e.target.value);
    };

    handleCheckboxChange = (e) => {
        this.props.onChange(e.target.name, e.target.checked);
    };

    handleFileChange = (type, url, File) => {
        this.props.onChange(this.props.fieldKey, File);
    };

    handleSelectChange = (e) => {
        this.props.onChange(e.target.name, e.target.value);
    };

    onHandleDateChange = (value, e) => {
        const { fieldKey, onChange } = this.props;

        if (onChange) {
            onChange(fieldKey, isNil(value) ? '' : DateTimeFormatHelper.format(value, 'MM/DD/YYYY'));
        }
    };

    handleInput = (event) => {
        const value = event.target.value;
        event.target.value = value.replace(/\D/g, '');
    };

    render() {
        const {
            props: {
                fieldKey,
                inputType,
                extra,
                label,
                required,
                value,
                description,
                heading,
                subHeading,
                content,
                sourceImage,
                theme,
                imagePosition,
                imageUrl,
                sourceDocument,
                imageImportance,
                error,
            },
        } = this;

        switch (inputType) {
            case 'social security number': {
                return (
                    <div className={`form-builder__textInput`}>
                        <label htmlFor={fieldKey}>
                            {!!label && <small>{label}</small>}
                            {!!description && <small>{description}</small>}
                            <input
                                type={'text'}
                                name={fieldKey}
                                id={fieldKey}
                                defaultValue={value}
                                className={error ? 'form-builder__error' : ''}
                                onChange={this.handleInputChange}
                                minLength={extra?.numberOfDigits}
                                maxLength={extra?.numberOfDigits}
                                onInput={this.handleInput}
                            />
                            {error && <small className={'form-builder__error-color'}>{error}</small>}
                        </label>
                    </div>
                );
            }
            case 'date of birth': {
                return (
                    <div className={`form-builder__optionsInput${error ? '__error' : ''} form-builder__date-field`}>
                        <DateTimePicker
                            onChange={this.onHandleDateChange}
                            onSelect={this.onHandleDateChange}
                            date={value}
                            dateFormat={'MM/dd/yyyy'}
                            maxDate={DateTimeFormatHelper.format()}
                            isClearable={true}
                            shouldCloseOnSelect={false}
                            preventFocus={true}
                            description={description}
                            isFormElement={true}
                            label={label}
                            id={fieldKey}
                            isCustomHeader={true}
                            isDefaultSelect={false}
                        />
                    </div>
                );
            }
            case 'textBlock': {
                return (
                    <div className='form-builder__text-card-block'>
                        <TextCard
                            key={fieldKey}
                            heading={heading}
                            subHeading={subHeading}
                            content={content}
                            sourceImage={sourceImage}
                            theme={theme}
                        />
                    </div>
                );
            }
            case 'checkbox': {
                return extra?.multiple && extra?.options?.length > 0 ? (
                    <div className={`form-builder__optionsInput${required ? ' required' : ''}`}>
                        {!!label && <small>{label}</small>}
                        {!!description && <small>{description}</small>}
                        <div className='options-input-container'>
                            {extra.options.map((option) => (
                                <label htmlFor={`${fieldKey}-${option.value}`} key={option?.value}>
                                    <input
                                        type='checkbox'
                                        name={fieldKey}
                                        id={`${fieldKey}-${option?.value}`}
                                        required={option?.required}
                                        data-fieldkey={fieldKey}
                                        onChange={(e) => {
                                            this.props.onMultipleCheckboxChange(
                                                fieldKey,
                                                option?.value,
                                                e.target.checked,
                                            );
                                        }}
                                    />
                                    {option?.value}
                                </label>
                            ))}
                        </div>
                        {error && <small className={'form-builder__error-color'}>{error}</small>}
                    </div>
                ) : (
                    <div className={`form-builder__${inputType}Input${required ? ' required' : ''}`}>
                        <label htmlFor={fieldKey}>
                            {!!description && <small>{description}</small>}
                            <div className='checkInput__wrapper'>
                                <input
                                    type={inputType}
                                    name={fieldKey}
                                    id={fieldKey}
                                    checked={value}
                                    onChange={this.handleCheckboxChange}
                                />
                                {label}
                            </div>
                            {error && <small className={'form-builder__error-color'}>{error}</small>}
                        </label>
                    </div>
                );
            }
            case 'text':
            case 'number': {
                return (
                    <div className={`form-builder__${inputType}Input`}>
                        <label htmlFor={fieldKey}>
                            {!!label && <small>{label}</small>}
                            {!!description && <small>{description}</small>}
                            <input
                                type={inputType}
                                name={fieldKey}
                                id={fieldKey}
                                className={error ? 'form-builder__error' : ''}
                                defaultValue={value}
                                onChange={this.handleInputChange}
                            />
                            {error && <small className={'mt-2 form-builder__error-color'}>{error}</small>}
                        </label>
                    </div>
                );
            }
            case 'textarea': {
                return (
                    <div className={`form-builder__textAreaInput`}>
                        <label htmlFor={fieldKey}>
                            {!!label && <small>{label}</small>}
                            {!!description && <small>{description}</small>}
                            <textarea
                                name={fieldKey}
                                id={fieldKey}
                                onChange={this.handleInputChange}
                                defaultValue={value}
                                className={error ? 'form-builder__error' : ''}
                            />
                            {error && <small className={'form-builder__error-color'}>{error}</small>}
                        </label>
                    </div>
                );
            }
            case 'radio': {
                return (
                    <div className={`form-builder__radioInput`}>
                        <label htmlFor={fieldKey}>
                            {!!label && <small>{label}</small>}
                            {!!description && <small>{description}</small>}
                            {extra.options.map((option) => (
                                <label htmlFor={`${fieldKey}-${option}`} key={option}>
                                    <input
                                        type={inputType}
                                        name={fieldKey}
                                        id={`${fieldKey}-${option}`}
                                        defaultValue={option}
                                        onChange={this.handleInputChange}
                                    />
                                    {option}
                                </label>
                            ))}
                        </label>
                        {error && <small className={'form-builder__error-color'}>{error}</small>}
                    </div>
                );
            }
            case 'dropdown': {
                return (
                    <div className={`form-builder__optionsInput`}>
                        <label htmlFor={fieldKey}>
                            {!!label && <small>{label}</small>}
                            {!!description && <small>{description}</small>}
                            <select
                                className={`dropdown-input ${error ? 'form-builder__error' : ''}`}
                                name={fieldKey}
                                defaultValue={value}
                                onChange={this.handleSelectChange}
                            >
                                <option value=''>Select a {label}</option>
                                {extra.options.map((option) => (
                                    <option value={option} key={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </label>
                        {error && <small className={'form-builder__error-color'}>{error}</small>}
                    </div>
                );
            }
            case 'date': {
                return (
                    <div className={`form-builder__optionsInput${error ? '__error' : ''}  form-builder__date-field`}>
                        <DateTimePicker
                            id={fieldKey}
                            onChange={this.onHandleDateChange}
                            onSelect={this.onHandleDateChange}
                            date={value}
                            isToShowTimeSelect={false}
                            dateFormat={'MM/dd/yyyy'}
                            isClearable={true}
                            error={error}
                            description={description}
                            isFormElement={true}
                            label={label}
                            isCustomHeader={true}
                        />
                    </div>
                );
            }
            case 'document': {
                return (
                    <div className='form-builder__document-card-block'>
                        <DocumentCard
                            heading={heading}
                            content={content}
                            sourceDocument={sourceDocument ?? this?.props?.file}
                            theme={theme}
                        />
                    </div>
                );
            }
            case 'image': {
                return (
                    <div className='form-builder__image-card-block'>
                        <ImageCard
                            heading={heading}
                            subHeading={subHeading}
                            content={content}
                            sourceImage={sourceImage ?? this?.props?.file}
                            imagePosition={imagePosition}
                            imageUrl={imageUrl}
                            theme={theme}
                            imageImportance={imageImportance}
                        />
                    </div>
                );
            }
            case 'file': {
                return (
                    <div className='form-builder__upload-file'>
                        <FileUpload
                            id={fieldKey ?? 'file'}
                            name={fieldKey}
                            label={label}
                            handleFileChange={this.handleFileChange}
                            type={inputType}
                            required={required}
                            accept={extra?.allowedFileTypes}
                            description={description}
                            validationError={error}
                            uploadedFileName={value?.name}
                            maxSize={2}
                            restrictMaxSize={true}
                        />
                    </div>
                );
            }
            default: {
                return <></>;
            }
        }
    }
}

import React, { Component } from 'react';
import './ErrorPage.scss';

interface IProps {
    className?: string;
    isIframe: boolean;
}
interface IState {}

export default class ErrorPageWithCustomMessage extends Component<IProps, IState> {
    state: IState = {};

    render() {
        const { className, isIframe } = this.props;
        return (
            <div className={`customr-error-page-wrapper ${className}`}>
                <header>
                    <img className='logo-img' src={'/logo.png'} alt='RealEstateU logo' />
                </header>
                <main>
                    <div className='content-wrapper'>
                        <i className='fa-solid fa-ban' />
                        <h1>Oh Sorry !</h1>
                        <div className='custom-error-message'>
                            {isIframe ? (
                                <p>IFrame Embedding is not supported due to security reasons !</p>
                            ) : (
                                <>
                                    <p>Electron browser is not supported due to security reasons !</p>
                                    <p>Kindly use other browser such as Chrome, Firefox, Edge & Safari.</p>
                                </>
                            )}
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
import { DATEPICKERMONTHS } from 'src/helpers/GlobalConstants';
import './CustomHeader.scss';
import { isNil, isUndefined } from 'lodash';
interface IProps {
    params: ReactDatePickerCustomHeaderProps;
    dateMode: string | null;
    selectedDate: null | Date | string;
    isDefaultSelect?: boolean;
}
interface IState {}

export default class CustomHeader extends Component<IProps, IState> {
    state: IState = {};
    render() {
        const {
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
        } = this.props.params;
        const { selectedDate, isDefaultSelect } = this.props;

        const currentYear = DateTimeFormatHelper.getYear();
        let startYear = currentYear;
        let endYear = currentYear;

        if (this.props.dateMode === 'lower' || this.props.dateMode === 'both') {
            startYear -= 100;
        }

        if (this.props.dateMode === 'upper' || this.props.dateMode === 'both') {
            endYear += 100;
        }

        if (this.props.dateMode === '') {
            startYear = 1901;
        }

        const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
        const months = DATEPICKERMONTHS;

        const yearValue = (() => {
            if (!isNil(isDefaultSelect)) {
                if (isNil(selectedDate)) {
                    return '';
                } else {
                    return DateTimeFormatHelper.getYear(date);
                }
            } else if (!isUndefined(selectedDate)) {
                return DateTimeFormatHelper.getYear(date);
            } else {
                return '';
            }
        })();

        const monthValue = (() => {
            if (!isNil(isDefaultSelect)) {
                if (isNil(selectedDate)) {
                    return '';
                } else {
                    return months[DateTimeFormatHelper.getMonth(selectedDate)];
                }
            } else if (!isUndefined(selectedDate)) {
                return months[DateTimeFormatHelper.getMonth(selectedDate)];
            } else {
                return '';
            }
        })();

        return (
            <div className='common-datepicker-header'>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        decreaseMonth();
                    }}
                    disabled={prevMonthButtonDisabled}
                >
                    <i className='fa-solid fa-arrow-left' />
                </button>
                <div className={`year-selection ${!selectedDate ? 'year-default' : ''}`}>
                    <select value={yearValue} onChange={({ target: { value } }) => changeYear(parseInt(value))}>
                        {!selectedDate && !isNil(isDefaultSelect) && !isDefaultSelect && (
                            <option value='' disabled>
                                {'Select Year'}
                            </option>
                        )}

                        {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>

                <div className='month-selection'>
                    <select value={monthValue} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                        {!selectedDate && !isNil(isDefaultSelect) && !isDefaultSelect && (
                            <option value='' disabled>
                                {'Select Month'}
                            </option>
                        )}

                        {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>

                <button
                    onClick={(e) => {
                        e.preventDefault();
                        increaseMonth();
                    }}
                    disabled={nextMonthButtonDisabled}
                >
                    <i className='fa-solid fa-arrow-right' />
                </button>
            </div>
        );
    }
}
